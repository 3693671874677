.ant-ribbon-wrapper {
    height: 100%;
}

.ant-upload-list-picture-card-container {
    height: 180px !important;
    width: 180px !important;
}

.ant-upload-list-item-thumbnail {
    opacity: 1.0 !important;
}
