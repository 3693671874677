@import "~antd/lib/style/themes/default";
@import "~antd/dist/antd.less";

@primary-color: #ed1776;
@border-radius-base: 8px;

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.make-space(@value:50);
.make-space(@value:40);
.make-space(@value:30);
.make-space(@value:20);
.make-space(@value:15);
.make-space(@value:10);
.make-space(@value:5);
.make-space(@value:0);

.make-space(@value) {
  .p@{value} {
    padding: ~"@{value}px";
  }
  .pt@{value} {
    padding-top: ~"@{value}px";
  }
  .pl@{value} {
    padding-left: ~"@{value}px";
  }
  .pr@{value} {
    padding-right: ~"@{value}px";
  }
  .pb@{value} {
    padding-bottom: ~"@{value}px";
  }
  .m@{value} {
    margin: ~"@{value}px";
  }
  .mt@{value} {
    margin-top: ~"@{value}px";
  }
  .ml@{value} {
    margin-left: ~"@{value}px";
  }
  .mr@{value} {
    margin-right: ~"@{value}px";
  }
  .mb@{value} {
    margin-bottom: ~"@{value}px";
  }
}

.text-center {
  text-align: center !important;
}

.overflow-x {
  overflow-x: auto;
}
